import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
// import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";
// import Features from "components/features/ThreeColWithSideImage.js";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

// import SupportIconImage from "images/support-icon.svg";
// import ShieldIconImage from "images/shield-icon.svg";
// import CustomerLoveIconImage from "images/simple-icon.svg";
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Features
        heading="About DevBuddy"  
      />
      <Features
        subheading={<Subheading>Our Mission</Subheading>}
        description="At DevBuddy, our mission is to provide you with a versatile and user-friendly utility app that simplifies a wide range of tasks. We believe that technology should be accessible and useful to everyone. Our goal is to make your life easier by offering a collection of powerful tools that can save you time and effort in your daily tasks"
        
      />
     <Features
        subheading={<Subheading>What We Offer</Subheading>}
        description="DevBuddy offers a comprehensive set of tools and utilities that cater to a diverse range of needs. Whether you're a developer, a designer, a student, or anyone in need of quick and reliable tools, we've got you covered. From converting Unix time to beautifying your code, our app is here to streamline your workflow.

"
        
      />
      <Features
        subheading={<Subheading>Our Commitment</Subheading>}
        description="We are committed to delivering the highest quality service to our users. We are constantly working to improve and expand our app's capabilities, and we take user feedback seriously. If you have suggestions or encounter any issues, please don't hesitate to contact us."
        
      />
      <Features
        subheading={<Subheading>Contact Us</Subheading>}
        description="We value your feedback and suggestions. If you have any questions, concerns, or just want to say hello, you can reach us at [your email address]. We're also active on social media, so feel free to connect with us on Twitter and Facebook."
        
      />
      <Features
        subheading={<Subheading>Thank You</Subheading>}
        description="We want to express our sincere gratitude to our users for choosing [Your App Name]. Your support and feedback drive us to keep improving and enhancing the app. We look forward to serving you for years to come."
        
      />
      <DecoratorBlob1 />
      <DecoratorBlob2 />
      <Footer />
    </AnimationRevealPage>
  );
};
