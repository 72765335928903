import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Features from "components/features/ThreeColSimple.js";
import { SectionHeading } from "components/misc/Headings";

// const HeadingRow = tw.div`flex`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const SecHeading3 = tw.h4`text-4xl sm:text-2xl font-black tracking-wide text-center`
// const Heading1 = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <Features
        heading="Privacy Policy"  
      />
       <Features 
        description="Last updated: April 21, 2024"
      />
       <Features
        description="This Privacy Policy describes DevBuddy is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you use our data inspection and debugging application ."  
      />
      <Features
        description="By using the Service, you consent to the practices described in this Privacy Policy. Please take the time to review this policy carefully."  
      />
      <Features
         heading={<SecHeading3>Information We Collect </SecHeading3>}
        subheading={<Subheading>We may collect the following types of information</Subheading>}
        description=" Personal Information: When you generate an account or use the Service, we may collect personal information, such as your name, email address, and contact information."  
      />
      <Features
        description="Data Inspection Data: The data you upload or access for data inspection and debugging, which may include your data files, configurations, and debugging activities."  
      />
      <Features
        description="Log Data: We collect information regarding your interactions with the Service, including IP address, browser type, operating system, and the pages you visit."  
      />
      <Features
        subheading={<Subheading>How We Use Your Information</Subheading>}
        description="We use the collected information for the following purposes:"  
      />
      <Features
        description="1. You means the individual accessing or using the Service, or the company, or other
                  legal entity on behalf of which such individual is accessing or using the Service, as applicable."  
      />
     <Features
        description= "2. Company (referred to as either the Company, We, Us or Our in this Agreement)analyze how the Service is used and diagnose technical issues. "/>
      <Features
        description= "3. Affiliate to analyze how the Service is used and diagnose technical issues communicate with you, including providing customer support and updates. "/>
      <Features
        description= "4. Website refers to DevBuddy "/>
        <Features
        description= "5. Service refers to the Website. "/>
        <Features
        description= "6. Country refers to: India "/>
        <Features
        description= "7. Service Provider  means any natural or legal person who processes the data on behalf of the Company. We do not sell, rent, or trade your personal information to third parties. However, we may share information with trusted third-party service providers who help us deliver the Service. They are contractually bound to keep your information confidential. "/>
       <Features
        description= "8. Third-party Social Media Service</strong> refers to any website or any social network website through which a User can log in or create an account to use the Service. "/>
        <Features
        description= "9. Personal Data</strong> is any information that relates to an identified or identifiable individual. "/>
       <Features
        description= "10. Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses. "/>
        <Features
        description= "11. Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit). "/>
      <Features
         heading={<SecHeading3>Collecting and Using Your Personal Data </SecHeading3>}
        subheading={<Subheading>Types of Data Collected</Subheading>}
        description="While using Our Service, We may ask You to provide Us with certain personally identifiable information
                   that can be used to contact or identify You. Personally identifiable information may include, but is not
               limited to:"/>
        <Features
        description= "Email address,First name and Last name,Phone number,Address, State, Province, ZIP/Postal code, City,Usage Data. "/>
     <Features
        subheading={<Subheading>Usage Data</Subheading>}
        description= "Usage Data is collected automatically when using the Service. "/>
     <Features
        description= "Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),browser type, browser version, the pages of our Service that You visit, the time and date of Your visit,the time spent on those pages, unique device identifiers and other diagnostic data. "/>
     <Features
        description= "When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data."/>
     <Features
        description= "We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.  "/>
     <Features
        subheading={<Subheading>Use of Your Personal Data</Subheading>}
        description= "The Company may use Personal Data for the following purposes: "/>
     <Features
        description= "To provide and maintain our Service</strong>, including to monitor the usage of our Service. "/>
     <Features
        description= "To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user. "/>
     <Features
        description= "For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.  "/>
      <Features
        description= "To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.  "/>
     <Features
        description= "To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.  "/>
     <Features
        description= "To manage Your requests:</strong> To attend and manage Your requests to Us. "/>
    <Features
        description= "We may share your personal information in the following situations:"/>
    <Features
        description= "With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You."/>
    <Features
        description= "For Business transfers:</strong> We may share or transfer Your personal information connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company."/>
    <Features
        description= "With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us."/>
    <Features
        description= "With Business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions."/>
    <Features
        description= "With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile."/>
        <Features
        subheading={<Subheading>Your Rights</Subheading>}
        description= "You have the right to access, correct, or delete your personal information.You may also have the right to object to or restrict certain data processing.To exercise these rights, please contact us at [Contact Email]"/>
<Features
        subheading={<Subheading>Changes to This Policy</Subheading>}
        description= "We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons.We will notify you of any changes by posting the new Privacy Policy on this page."/>
<Features
        subheading={<Subheading>Contact Us</Subheading>}
        description= " If you have any questions or concerns about this Privacy Policy, please contact us at [Contact Email]."/>

      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
