import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";







// const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        heading="🚀 Powerful Developer Tools"
        description=" 47+ hand crafted developer tools for your everyday tasks whenever you need it. 
            Fully customizable and configurable."
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        heading="🌐 Work Offline"
        description="Stop pasting your data to random websites online.
DevUtils works entirely offline! Everything you paste into the app never leaves your machine."
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <MainFeature1
        heading="🎲 Generators / Decoders"
        description="Generate QR code, Lorem Ipsum, UUID, Nano ID, MD5/SHA1/SHA2 hash,... Encode and decode URL string, Base64 string, HTML entities,..."

        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        heading="⚡ Smart Detection"
        description="
DevUtils automagically detects the right tool based on your clipboard content. For example:
⚡ 1611241901 → Unix Time Converter
⚡ {abc: 123, ...} → JSON Formatter
⚡ aGVsbG8gd29ybGQ= → Base64 Decoder
Activate by clicking to iconDevUtils status bar iconin the status bar."
        buttonRounded={false}
        // primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <MainFeature1
        heading="🔍 Inspector / Debugger"
        description=" Easily inspect and debug your data with DevUtils's fast, flexible, and intuitive UI.
JWT Debugger, RegExp Tester, HTML Preview, Text Diff Checker, String Inspector..."
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        heading="🎨 The little details!"
        description="💎 Delightful handcrafted native UI
                    🔍 Organize tools into group
                    🌈 Code syntax highlighting
                      🌙 Light/dark theme"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <MainFeature1
        heading="🔁 Converter / Parser"
        description="Quickly convert between data types, unit, formats.
Unix Time Converter, Query String to JSON, YAML to JSON, JSON to YAML, Number Base Converter, JSON to CSV, CSV to JSON, HTML to JSX... "
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />

      <Footer />
    </AnimationRevealPage>
  );
};
