import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionDescription } from "components/misc/Typography.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(
  tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`
);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  subheading = "FAQS",
  heading = "You have Questions ?",
  description = "And we have got answers to all of them.",
  faqs = [
    {
      question: "What happens to my data ?",
      answer:
        "Your data will never leave your machine. DevUtils.app works entirely offline, the only internet usage is for license verification and auto-update, which is disabled by default.",
    },
    {
      question: "How does license work?",
      answer:
        `The license is a perpetual license. One license key allows you to use the app indefinitely. You are also eligible for one year of free updates.
If you don't want to renew your license after one year, you can continue to use the last version of DevUtils that you have for as long as you want.
You can renew your license here with a 40% discount for another year of updates. That means the renew price is 60% of your purchase price (before discount) and won't be affected if the app price increases in the future. (e.g., $24 for the Basic License)`,
    },
    {
      question: "Can I change the license key to another computer after I activate it?",
      answer:
        `Yes. Just go to DevUtils > Preferences... > License > Unlink Device and follow the instruction. Or you can log in to License Manager with your purchase email. You will see all connected devices and unlink any device you want.`,
    },
    {
      question: "Is there any feature difference between the app versions from Direct Download, App Store, and Setapp?",
      answer:
        `No. All app features are the same.`,
    },
    {
      question: "Is VAT included?",
      answer:
        `The price you see may or may not include VAT based on your country. If you want a VAT refund, please see the instruction in your receipt email.`,
    },
    {
      question: "Can I get a refund?",
      answer:
        `There is a 30-day money back guarantee, no questions asked. However, I do love to hear your feedback. Please send a refund request to me 💌 support@devutils.com I'll process as soon as I can.`,
    },
    {
      question: "I have lost my license key, how do I get it back?",
      answer:
        `Please use Restore Purchase with your purchase email, the license key will be resend to you.`,
    },
    {
      question: "How to add more seats to my license?",
      answer:
        `For now, you will have to buy a new license. There is a volume discount for team license if you plan to buy more than 3 seats. Do contact me if you want a custom license: 💌 support@devutils.com.`,
    },
    {
      question: "What are the open-source projects this app uses?",
      answer:
        `Please see the acknowledgments.`,
    },
    {
      question: "Is there a version for Windows or Linux?",
      answer:
        `Yes! Windows/Linux version is being developed. If you want to get notified, subscribe to our Product Updates newsletter.`,
    },
  ],
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <AnimationRevealPage>
      <Header />
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 },
                    }}
                    initial="collapsed"
                    animate={
                      activeQuestionIndex === index ? "open" : "collapsed"
                    }
                    transition={{
                      duration: 0.02,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" },
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
      <Footer />
    </AnimationRevealPage>
  );
};
